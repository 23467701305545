$primary: #0e4d92;
$secondary: #f1f1f1;
$button: #24c7f6;

.users {
  // padding: 3rem 0 3rem 0 !important;
  padding: 0rem 0 3rem 0 !important;
  // .myButton {
  //   background-color: $button;
  //   color: $secondary;
  //   border-radius: 3px;
  //   border: unset;
  //   padding: 9px 30px;
  //   margin-bottom: 5px;
  //   box-shadow: 5px 5px 5px #e6e3e3;
  //   position: absolute;
  //   right: 10%;
  // }
}
.text {
  padding-left: 3rem !important;
}


