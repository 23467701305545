.statusModal {
  margin: auto;
  display: block;
  width: 80%;


  .close_modal {
    float: right;
  }




  // .btnDelete {
  //   background-color: #ff8543;
  //   color: white;
  //   width: 39%;

  // }

  .btnEdit {
    background-color: #0e4d92;
    color: white;
    width: 39%;
  }
}

.show_header {
  display: flex;
  justify-content: end;
  color: #0e4d92;


}

.myButton {

  border: unset;
  background: transparent;

}

.showfiles_modal {
  margin: auto;
  display: block;
  // width: 80%;
  color: #0e4d92;


  .close_modal {
    float: right;
  }

  .myImages {
    // left: 0;
    // top: 0;
    // width: 100%;

    // height: 100%;

    overflow: hidden;

    .show_images {
      // padding: 0px 76px;
      // width: 66%;


      img {
        width: 65%;
      }
    }
  }

  .video {
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: hidden;

    .show_video {
      padding: 0px 76px;
    }
  }
}