$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$button: #24c7f6;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);

.news {
  // padding: 3rem 0 3rem 0 !important;
  padding: 0rem 0 3rem 0 !important;

}

// form {
//   width: 50%;
//   select {
//     width: 100%;
//     padding: 10px;
//     margin-bottom: 5px;
//     border-radius: 5px;
//   }
//   .lang-btn {
//     text-decoration: unset;
//     color: $primary-shade;
//     padding: 5px 10px;
//     border-bottom: $primary-shade 4px solid;
//     margin-right: 10px;
//     cursor: pointer;
//   }
//   .lang-btn-active {
//     text-decoration: unset;
//     color: $primary;
//     padding: 5px 10px;
//     border-bottom: $primary 4px solid;
//     margin-right: 10px;
//     cursor: pointer;
//   }
//   input {
//     width: 100%;
//     padding: 10px;
//     margin-bottom: 5px;
//     border-radius: 5px;
//   }
//   textarea {
//     width: 100%;
//   }
//   button {
//     width: 50%;
//     padding: 10px;
//     margin-bottom: 5px;
//     border-radius: 5px;
//   }
// }
