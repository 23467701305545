$primary: #0e4d92;
$secondary: #f1f1f1;

.login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: hsl(211, 83%, 31%);
  text-align: center;
  .login-logo {
    padding-top: 10%;
  }
  .login-wraper {
    // position: inline;
    margin: auto;
    width: 30%;
    border-left: $secondary solid 5px;
    border-radius: 0 20px 20px 0;
    padding: 20px;
    margin-top: 7%;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
    transition: 0.3s ease-in-out;
    &:hover,
    &:focus {
      border-left: $secondary solid 25px;
      box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.2);
    }
    form {
      width: 100%;

      input {
        width: 100%;
        background-color: $primary;
        color: $secondary;
        border: $secondary 2px solid;
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
        &:focus {
          outline: none;
        }
      }

      button {
        background-color: $primary;
        border: $secondary 2px solid;
        border-radius: 10px;
        box-decoration-break: unset;
        transition: 0.3s ease-in-out;
        color: $secondary;
        width: 50%;
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
        &:hover,
        &:focus {
          // border: $secondary 5px solid;
          width: 100%;
          cursor: pointer;
        }
      }
    }

    // @media only screen and (max-width: 780px) {
    //   width: 90%;
    //   margin-top: 30%;
    // }
  }
}

@media only screen and (max-width: 1024px) {
  .login {
    .login-wraper {
      width: 50%;
      margin-top: 13%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .login {
    .login-wraper {
      width: 60%;
      margin-top: 15%;
    }
    .login-logo {
      padding-top: 15%;
      img {
        width: 270px;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .login {
    .login-wraper {
      width: 60%;
      margin-top: 25%;

      form {
        width: 100%;

        input {
          width: 100%;
          background-color: $primary;
          color: $secondary;
          border: $secondary 2px solid;
          padding: 7px;
          margin-bottom: 7px;
          border-radius: 5px;
          &:focus {
            outline: none;
          }
        }

        button {
          background-color: $primary;
          border: $secondary 2px solid;
          border-radius: 10px;
          box-decoration-break: unset;
          transition: 0.3s ease-in-out;
          color: $secondary;
          width: 50%;
          padding: 10px;
          margin-bottom: 5px;
          border-radius: 5px;
          &:hover,
          &:focus {
            // border: $secondary 5px solid;
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
    .login-logo {
      padding-top: 25%;
      img {
        width: 230px;
        height: auto;
      }
    }
  }
}
