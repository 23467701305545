$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);
.dashboard {
  padding: 0rem 0 3rem 0 !important;

  .myButton {
    background-color: $primary;
    color: $secondary;
    border-radius: 5px;
    border: unset;
    padding: 10px;
    margin-bottom: 5px;
  }

  .cases {
    padding-left: 3rem !important;
  }

  .new-discussion {
    width: 30%;
    float: left;
    margin-right: 10px;
    margin-bottom: 15px;
    // background-color: #f1f1f1;
    border-radius: 12px;
    .thread {
      width: 90%;
      background-color: $primary;
      color: $secondary;
      border-radius: 30px;
      padding: 25px;
      padding-left: 40px;
      // margin-bottom: 20px;
      &:hover {
        box-shadow: $myshaddow;
      }
    }

    .comment {
      width: 80%;
      background-color: $primary-shade;
      border-radius: 30px;
      padding: 20px;
      padding-left: 40px;
      margin-top: -22px;
      color: $secondary;
      float: right;
      transition: 0.3s ease-in;
      &:hover {
        box-shadow: $myshaddow;
        width: 85%;
      }

      .icon {
        position: relative;
        width: 45px;
        top: -25px;
        left: -50px;
        background-color: $primary-shade;
        color: $secondary;
        border-radius: 50%;
        padding: 10px 15px;
        box-shadow: $myshaddow;
      }

      .content {
        width: 100%;
        margin-top: -45px;
        padding-left: 15px;
      }

      button {
        margin-right: 5px;
        background-color: $primary;
        border: unset;
        color: $secondary;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        transition: 0.3s ease-in-out;
      }

      .actions {
        transition: 0.3s ease-in-out;
        margin-top: 10px;
        .publish:hover {
          color: green;
          padding: 5px 15px;
        }
        .translate:hover {
          color: yellowgreen;
          padding: 5px 15px;
        }
        .delete:hover {
          color: red;
          padding: 5px 15px;
        }
      }
    }

    .reply-comment {
      width: 85%;
      background-color: $secondary;
      border-radius: 30px;
      padding: 20px;
      padding-left: 40px;
      margin-top: -22px;
      color: $primary;
      float: right;
      transition: 0.3s ease-in;
      &:hover {
        box-shadow: $myshaddow;
        // width:85%;
      }
      .icon {
        position: relative;
        top: -25px;
        left: -35px;
        background-color: $primary-shade;
        color: $secondary;
        border-radius: 50%;
        padding: 10px 15px;
        box-shadow: $myshaddow;
      }
      .content {
        width: 100%;
        margin-top: -35px;
        padding-left: 15px;
      }
    }

    .reply {
      width: 80%;
      background-color: $primary-shade;
      border-radius: 30px;
      padding: 20px;
      padding-left: 40px;
      margin-top: -15px;
      margin-left: 35px;
      color: $secondary;
      float: left;
      transition: 0.3s ease-in;

      &:hover {
        box-shadow: $myshaddow;
        width: 85%;
      }
      .icon {
        position: relative;
        top: -25px;
        left: -35px;
        background-color: $primary-shade;
        color: $secondary;
        border-radius: 50%;
        padding: 10px 15px;
        box-shadow: $myshaddow;
      }
      .content {
        width: 100%;
        margin-top: -45px;
        padding-left: 15px;
      }

      button {
        margin-right: 5px;
        background-color: $primary;
        border: unset;
        color: $secondary;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        transition: 0.3s ease-in-out;
      }
      .actions {
        transition: 0.3s ease-in-out;
        margin-top: 10px;
        .publish:hover {
          color: green;
          padding: 5px 15px;
        }
        .translate:hover {
          color: yellowgreen;
          padding: 5px 15px;
        }
        .delete:hover {
          color: red;
          padding: 5px 15px;
        }
      }
    }
  }
}
