
.statistics{
    h3{
        margin: 10px;
        padding: 5px;
        padding-left: 20px;
        width: 60%;
        color: rgb(14,77,146);
        background-color: rgba(205, 217, 228, 0.52);
        border-radius: 20px 100px 0px 20px;
    }
    .charts{
        margin-bottom: 30px;
    }
    .date-set{
        font-size: 18px;
        width: 100%;
        padding-bottom: 20px;
        // text-align-last: center;
        input{
            margin: 1% 1%;
            padding: 3px 8px;
            border-radius: 8px;
            border: 1px solid rgb(14,77,146);
        }
        button{
            padding: 5px 20px;
            background-color: rgb(14,77,146);
            color: white;
            border-radius: 8px;
            outline: none;
            border-style: none;
        }
    }

    .chart-title{
        display: flex;
        align-items: center;
    }
    .excel-btn{
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
    }
    .excel-btn i{
        color: green;
    }
}