$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$button: #24c7f6;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);

.community {
  padding: 0 0 3rem 0 !important;
  .page-title {
    h1 {
      padding-top: 7px;
    }
  }
}
