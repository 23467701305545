$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);


.case-discussion {
  padding: 0rem 0 3rem 0 !important;

  // .header-profile {
  //   display: none !important;

  // }

}

.cases_d {
  width: 100%;
  padding: 10px 0;
  margin-top: -47px;

  .case_info {
    width: 100%;
    padding: 10px 0 20px 0;

    .case_title {
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
      color: $primary;
    }
  }

  .new-discussion {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 30px;
    border-radius: 12px;
    height: 79vh;
    overflow-y: scroll;
    // width: 100%;
    // margin-right: 10px;
    // margin-bottom: 30px;
    // border-radius: 12px;

    .chat_info {
      border-bottom: 1px solid rgb(228, 222, 222);

      p {
        margin-bottom: 0;
      }

      .case-title {
        text-align: left;
        padding: 6px 13px 0px 15px;
        color: #0e4d92;
        /* font-size: larger !important; */
        font-weight: 500;
        font-size: large;
        line-height: 23px;

        .showMore {
          background-color: #0e4d92;
          border: unset;
          color: #fff8f8;
          border-radius: 10px;
          padding: 5px 24px;
          font-size: 17px;
          margin-left: 17px;

        }

        a {
          color: #0e4d92 !important;
        }
      }
    }

    .chat_info {
      position: sticky;
      top: 0;
      padding: 7px 18px;
      width: 100%;
      background-color: #fff;
      margin-bottom: 10px;

      img {
        object-fit: cover;
        vertical-align: middle;
      }
    }

    .discussions {
      display: flow-root;
    }

    .thread {
      width: auto;
      background-color: $primary;
      color: $secondary;
      border-radius: 30px;
      padding: 15px 30px;
      // padding-left: 40px;
      // padding-bottom: 30px;
      margin-bottom: 15px;
      margin-left: 10px;
      float: right;
      box-shadow: $myshaddow;

      // padding-right: 40px;
      &:hover {
        box-shadow: $myshaddow;
      }
    }

    .comment {
      width: 80%;
      background-color: $primary-shade;
      border-radius: 30px;
      padding: 20px;
      padding-left: 40px;
      margin-top: -22px;
      color: $secondary;
      float: right;
      transition: 0.3s ease-in;

      &:hover {
        box-shadow: $myshaddow;
        width: 85%;
      }

      .icon {
        position: relative;
        width: 45px;
        top: -25px;
        left: -50px;
        background-color: $primary-shade;
        color: $secondary;
        border-radius: 50%;
        padding: 10px 15px;
        box-shadow: $myshaddow;
      }

      .content {
        width: 100%;
        margin-top: -45px;
        padding-left: 15px;
      }

      button {
        margin-right: 5px;
        background-color: $primary;
        border: unset;
        color: $secondary;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        transition: 0.3s ease-in-out;
      }

      .actions {
        transition: 0.3s ease-in-out;
        margin-top: 10px;

        .publish:hover {
          color: green;
          padding: 5px 15px;
        }

        .translate:hover {
          color: yellowgreen;
          padding: 5px 15px;
        }

        .delete:hover {
          color: red;
          padding: 5px 15px;
        }
      }
    }

    .reply-comment {
      width: auto;
      background-color: $secondary;
      border-radius: 30px;
      padding: 15px 30px;
      // padding: 20px;
      // padding-right: 40px;
      // margin-top: -22px;
      color: $primary;
      float: left;
      transition: 0.3s ease-in;
      box-shadow: $myshaddow;
      margin-bottom: 20px;
      // margin-left: 50px;
      margin-left: 20px;

      &:hover {
        box-shadow: $myshaddow;
        // width:85%;
      }

      // .icon {
      //   position: relative;
      //   top: -25px;
      //   left: -35px;
      //   background-color: $primary-shade;
      //   color: $secondary;
      //   border-radius: 50%;
      //   padding: 10px 15px;
      //   box-shadow: $myshaddow;
      // }
      .content {
        width: 100%;
        // margin-top: -35px;
        padding-left: 15px;
      }
    }

    .reply {
      width: 80%;
      background-color: $primary-shade;
      border-radius: 30px;
      padding: 20px;
      padding-left: 40px;
      margin-top: -15px;
      margin-left: 35px;
      color: $secondary;
      float: left;
      transition: 0.3s ease-in;

      &:hover {
        width: 85%;
      }

      .icon {
        position: relative;
        top: -25px;
        left: -35px;
        background-color: $primary-shade;
        color: $secondary;
        border-radius: 50%;
        padding: 10px 15px;
        box-shadow: $myshaddow;
      }

      .content {
        width: 100%;
        margin-top: -45px;
        padding-left: 15px;
      }

      button {
        margin-right: 5px;
        background-color: $primary;
        border: unset;
        color: $secondary;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        transition: 0.3s ease-in-out;
      }

      .actions {
        transition: 0.3s ease-in-out;
        margin-top: 10px;

        .publish:hover {
          color: green;
          padding: 5px 15px;
        }

        .translate:hover {
          color: yellowgreen;
          padding: 5px 15px;
        }

        .delete:hover {
          color: red;
          padding: 5px 15px;
        }
      }
    }
  }

  .listStatuses {
    height: 100px;
    overflow-y: auto;
    border: 1px solid #5b9ce1;

    .statusItem {
      // background-color: #f1f1f1;
      // padding: 0 20px;
      // // border-radius: 5px;
      // margin-bottom: 5px;
      // ;
      background-color: #fafafa;
      padding: 0 20px;
      margin-bottom: 3px;
      margin-top: 3px;
      border-right: 1px solid #cce5ff;
      border-bottom: 1px solid #5b9ce1;
    }

    .statusItemdisabled {
    cursor: auto;
    }
  }

  .post_message {
    // width: 100%;
    width: 100%;
    position: fixed;
    bottom: 0;
    // background-color: #fff;
    // margin-left: 17px;
    padding-bottom: 15px;

    form {
      width: 75%;

      span {
        float: right;
        // margin-top: -60px;
      }

      button {
        background-color: #fff !important;
        color: #0e4d92 !important;
        border: unset;
        color: #f1f1f1;
        width: auto;
        float: right;
        /* margin-top: -30px; */
        position: absolute;
        right: 26%;
        bottom: 30%;
        outline: none;
      }

      input {
        height: 70px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .cases_d {
    .post_message form {
      width: 76% !important;
    }

    .new-discussion {
      .thread {
        padding: 9px 23px;
        font-size: 14px;
        width: auto;
      }

      .chat_info {
        font-size: 12.7px;
      }

      .reply-comment {
        padding: 9px 23px;
        font-size: 14px;
      }
    }
  }

  .app-header .content_close .cases_d .post_message .message-form {
    width: 92% !important;
  }

  // .date-set{
  //   padding-bottom: 20px;
  //   input{
  //       margin: 2% 1%;
  //       padding: 3px 8px;
  //       border-radius: 8px;
  //       border: 1px solid rgb(14,77,146);
  //   }
  //   button{
  //       padding: 5px 20px;
  //       background-color: rgb(14,77,146);
  //       color: white;
  //       border-radius: 8px;
  //       outline: none;
  //       border-style: none;
  //   }
  // }
}

@media only screen and (max-width: 590px) {
  .cases_d {
    .post_message form {
      width: 100% !important;
      margin-left: 12px;

      button {
        right: 4%;
      }
    }

    .new-discussion {
      padding-right: 0 !important;
      margin-right: 0 !important;

      .chat_info {
        font-size: 12.7px;
        padding: 0 20px;
      }
    }

    .app-header .content_close .cases_d .post_message .message-form {
      width: 100% !important;
      margin-left: 20px;
    }
  }
}