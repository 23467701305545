$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$button: #24c7f6;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);

.show_files {
  padding: 0rem 0 3rem 0 !important;
  border: 3px solid red;
  img{
    width: 100%;
    // border-radius: ;
    padding-bottom: 10%;
  }
  .video{
    video{
      width: 100%;
      // padding-top: 0;
    }
    border: 3px solid green;
  }
}
