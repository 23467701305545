@import url("https://fonts.googleapis.com/css?family=Lobster");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
$primarycolor: #0e4d92;
$secondarycolor: #5b9ce1;

.navbar__ {
  .navbar {
    float: right;
    padding-top: 10px;
    padding-right: 5px;
    // display: block;
    // position: absolute;
    // top: 0;
    // right: 0;
    // transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    // height: 80px;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
  }

  .nav-menu {
    background-color: $primarycolor;
    width: 100%;
    height: 100vh;
    // display: flex;
    // justify-content: center;
    position: fixed;
    // right: -70%;
    left: 60%;
    top: 0;
    transition: 850ms;
    z-index: 99;
  }
  .active {
    left: 100%;
    transition: 350ms;
  }
  .nav-text {
    display: flex;
    justify-content: flex-start;

    list-style: none;
    height: 30px;
    a {
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      // width: 20%;
      height: 100%;
      align-items: flex-start;
      // padding: 0 16px;
      border-radius: 4px;
    }
  }

  .nav-text a:hover {
    // background-color: aquamarine;
    // background-color: #173142;
    background-color: $secondarycolor;
    padding: 5px;
  }
  .nav-menu-items {
    width: 100%;
    color: #fff;
    text-align: left;
  }
  .navbar-toggle {
    background-color: $primarycolor;
    width: 22%;
    height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 35px;
  }
  .menu-bars {
    color: #5b9ce1;
    font-size: 27px;
    padding-top: 30px;
    :hover {
      color: $secondarycolor;
    }
  }
  .menu_icon {
    padding-top: 0px;
    padding-right: 5px;
    color: #5b9ce1;
    :hover {
      color: $secondarycolor;
    }
  }
  .list_items {
    padding-top: 50px;
    ul {
      list-style: none;
      color: #fff;
      li {
        margin-bottom: 3px;
        a {
          text-decoration: none;
          color: #fff;
          font-size: 12.7px;

          &:hover {
            // background-color: aquamarine;
            // background-color: #173142;
            background-color: $secondarycolor;
            padding: 5px;
            border-radius: 5px;
            padding-right: 50%;
          }
        }
      }
    }
  }
  span {
    margin-left: 16px;
  }

  .social_media {
    // padding-top: 10%;
    width: 30%;
    position: absolute;
    bottom: 9%;

    ul {
      text-align: center;
      padding: 0;
      a {
        text-decoration: none;
        li {
          list-style: none;
          display: inline-block;
          // padding-right: 30px;
          cursor: pointer;
          color: #fff;
          vertical-align: text-bottom;
          padding: 0 10px;
          font-size: 15px;
          &:hover {
            color: $secondarycolor;
            padding: 3px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .navbar__ .navbar {
    padding-right: 23px;
  }
}
